import styles from "./home.module.css";

// Components
import { BouncerCard } from "../../components/BouncerCard/BouncerCard";
import React, { useEffect, useState } from "react";

export default function Home() {
  const [smallBouncers, setSmallBouncers] = useState([]);
  const [mediumBouncers, setMediumBouncers] = useState([]);
  const [specialtyBouncers, setSpecialtyBouncers] = useState([]);
  const [hugeBouncers, setHugeBouncers] = useState([]);
  const [premiumBouncers, setPremiumBouncers] = useState([]);

  useEffect(() => {
    const fetchCastles = async () => {
      const response = await fetch("/api/castle");
      const data = await response.json();

      setSmallBouncers([]);
      setMediumBouncers([]);
      setSpecialtyBouncers([]);
      setHugeBouncers([]);
      setPremiumBouncers([]);

      data.forEach((castle) => {
        const img = new Image();
        img.src = castle.path;

        if (castle.castleId[0] === "K") {
          setSmallBouncers((prev) => [...prev, castle]);
        } else if (castle.castleId[0] === "M") {
          setMediumBouncers((prev) => [...prev, castle]);
        } else if (castle.castleId[0] === "R") {
          setSpecialtyBouncers((prev) => [...prev, castle]);
        } else if (castle.castleId[0] === "G") {
          setHugeBouncers((prev) => [...prev, castle]);
        } else if (castle.castleId[0] === "X") {
          setPremiumBouncers((prev) => [...prev, castle]);
        } else {
          return;
        }
      });
    };

    const header = new Image();
    header.src = "/header-wide.webp";

    fetchCastles();
  }, []);

  return (
    /* Header */
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.item}>
          <a href="/">
            <img src="/logo.png" alt="Bouncy Castle King Logo" />
          </a>
        </div>

        <a href="tel:416-525-6255" className={styles.contactInfo}>
          Call Now
          <br />
          416-525-6255
        </a>
      </div>

      <img
        alt="Bouncy Castle King"
        src="/header-wide.webp"
        style={{
          width: "100%",
          height: "auto",
        }}
      />

      {/* Second Model */}
      <div className={styles.smallBouncers}>
        <h2 className={styles.sectionTitle}>Small Bouncers</h2>
        <div className={styles.smallBouncersText}>
          <p>
            <strong>
              <a href="tel:416-525-6255">Call (416) 525-6255</a> now to book
              your bouncy castle!
            </strong>
          </p>
          <p>
            Price includes delivery, set up and take down, to Brampton, Bolton,
            Caledon, Georgetown, Mississauga, Etobicoke, Oakville, Vaughan,
            Maple, Woodbridge, Schomberg, North York.
          </p>
          <p>
            Add $50 for delivery to Orangeville, Tottenham, Bradford, Newmarket.
          </p>
          <p>
            Add $75 Toronto east of Yonge to 404 Toronto south of Lawrence,
            Aurora, Burlington, Alliston, Erin, Milton, Stouffville. Call for
            Scarborough, Pickering, Hamilton, Waterdown or Barrie.
          </p>
          <p>Call for your area if not listed, you might get lucky!</p>
        </div>
        <div className={styles.card}>
          {smallBouncers.map((castle) => (
            <div className={styles.col} key={castle.castleId}>
              <BouncerCard
                title={castle.name}
                image={castle.path}
                setup={castle.setup}
                occupancy={castle.occupancy}
                maxWeight={castle.maxWeight}
                maxAge={castle.maxAge}
                size={`${castle.length}’L x ${castle.width}’W x ${castle.height}’H`}
                price={castle.price}
                castleId={castle.castleId}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.mediumBouncers}>
        <h2 className={styles.sectionTitle}>Medium Bouncers</h2>
        <div className={styles.card}>
          {mediumBouncers.map((castle) => (
            <div className={styles.col} key={castle.castleId}>
              <BouncerCard
                title={castle.name}
                image={castle.path}
                setup={castle.setup}
                occupancy={castle.occupancy}
                maxWeight={castle.maxWeight}
                maxAge={castle.maxAge}
                size={`${castle.length}’L x ${castle.width}’W x ${castle.height}’H`}
                price={castle.price}
                castleId={castle.castleId}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.specialtyBouncers}>
        <h2 className={styles.sectionTitle}>Specialty Bouncers</h2>
        <div className={styles.card}>
          {specialtyBouncers.map((castle) => (
            <div className={styles.col} key={castle.castleId}>
              <BouncerCard
                title={castle.name}
                image={castle.path}
                setup={castle.setup}
                occupancy={castle.occupancy}
                maxWeight={castle.maxWeight}
                maxAge={castle.maxAge}
                size={`${castle.length}’L x ${castle.width}’W x ${castle.height}’H`}
                price={castle.price}
                castleId={castle.castleId}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.hugeBouncers}>
        <h2 className={styles.sectionTitle}>Combination Bouncers with Slide</h2>
        <div className={styles.card}>
          {hugeBouncers.map((castle) => (
            <div className={styles.col} key={castle.castleId}>
              <BouncerCard
                title={castle.name}
                image={castle.path}
                setup={castle.setup}
                occupancy={castle.occupancy}
                maxWeight={castle.maxWeight}
                maxAge={castle.maxAge}
                size={`${castle.length}’L x ${castle.width}’W x ${castle.height}’H`}
                price={castle.price}
                castleId={castle.castleId}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.premiumBouncers}>
        <h2 className={styles.sectionTitle}>Premium Bouncers</h2>
        <div className={styles.card}>
          {premiumBouncers.map((castle) => (
            <div className={styles.col} key={castle.castleId}>
              <BouncerCard
                title={castle.name}
                image={castle.path}
                setup={castle.setup}
                occupancy={castle.occupancy}
                maxWeight={castle.maxWeight}
                maxAge={castle.maxAge}
                size={`${castle.length}’L x ${castle.width}’W x ${castle.height}’H`}
                price={castle.price}
                castleId={castle.castleId}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Want to Know More */}
      <div className={styles.extraInfoBlock}>
        <h2>Want to Know More?</h2>
        <p>
          The Bouncy Castle King is your Bouncy Castle rental headquarters for
          bouncy house rentals and more. Delivering FUN since 2009. 15 years in
          business, Thousands of happy customers, and new friends. We carry a
          wide selection of inflatable bouncy castles. Entertain the kids for
          hours at your next event, with one of our commercial Jumping castles.
        </p>
        <p>All bouncy castles are commercial-grade, Durable, and Clean.</p>
        <p>
          We service the following areas for delivery and set up, Brampton,
          parts of Toronto, Etobicoke, Woodbridge, Vaughan, Maple, Brampton,
          Bolton, Caledon, Nobleton, Schomberg, Mississauga, Oakville, Richmond
          Hill, and surrounding areas.
        </p>
        <p>
          Call for availability if your city is not listed. We often deliver to
          Orangeville, Erin, Mono, Milton, Tottenham, Alliston, Bradford,
          Burlington, Waterdown, Hamilton, Markham, Stouffville, Aurora,
          Scarborough, Toronto east of Yonge, Toronto South of Eglington.
          Delivery charges apply for these areas. Please call 416-525-6255 for
          price.
        </p>
        <p>
          Add fun to your next event with an inflatable bouncy house from the
          Bouncy Castle King. Whether the party is big or small, fun will be had
          by all. The world’s best babysitter. The kids will be entertained for
          hours, the parents can relax, knowing the kids are in a safe
          environment which also promotes physical activity.
        </p>
        <p>
          We have a huge selection of Bouncy Castles to accommodate large or
          small to medium-sized backyard spaces. For smaller-sized backyards we
          recommend one of our 10ft long x 10ft wide or 13ft x 13ft bouncers.
          Depending on your yard space you don’t want to have a yard full of
          bouncy house fun and no room for the rest of the party. Otherwise, our
          15ftx15ft and up to 22ftx18ft bouncers would be a better fit for a
          medium or larger-sized backyard. Take a look at our combo Castles with
          slides for even more fun.
        </p>
        <p>
          If you are not sure which bouncy house is best suited for your needs,
          We are always available to help….we’re just a phone call away.
        </p>
        <p>Our Bouncy Castles are ideal for Indoor/Outdoor setups.</p>
        <p>
          Call Now! to book your Bouncy Castle, We are available to serve you 7
          days a week!!
        </p>
      </div>

      {/* FAQ */}
      <div className={styles.FAQBlock}>
        <h2>FAQ</h2>
        <div>
          <p>
            <strong>Question: </strong>I didn’t reserve a bouncy castle and the
            party is tomorrow…can I still get one?
            <br />
            <strong>Answer: </strong>Yes of course we always do our best to
            accommodate everybody when possible, as long as we have something
            available it is first come first serve.
          </p>

          <p>
            <strong>Question: </strong>What if it rains the day of the party?
            <br />
            <strong>Answer: </strong>In the event of cancelation of your Bouncy
            Castle rental due to rain or unforeseen weather, your deposit will
            be refunded 100%
          </p>

          <p>
            <strong>Question: </strong>What if it starts raining 2 hours into
            the party?
            <br />
            <strong>Answer: </strong>There will be no refunds or discounts after
            your inflatable has been delivered to your address.
          </p>

          <p>
            <strong>Question: </strong>What if I have to change the date of the
            party after I booked due to weather or a conflicting date, do I
            loose the deposit?
            <br />
            <strong>Answer: </strong>No, we would simply secure a bouncer for
            you, for your new date, although your original choice may not be
            available.
          </p>

          <p>
            <strong>Question: </strong>How far in advance should I book the
            Bouncy House?
            <br />
            <strong>Answer: </strong>As soon as you have decided on a date, it
            is best to call and reserve your Jumpy Castle.
          </p>

          <p>
            <strong>Question: </strong>Can the bouncy castle be set up indoors?
            <br />
            <strong>Answer: </strong>Yes, your chosen Bouncy Castle can be set
            up indoors and outdoors provided there is room.
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className={styles.Footer}>
        <div className={styles.BookNowContainer}>
          <a href="contact" className={styles.BookNow}>
            Book for Your Event Now!
          </a>
        </div>
        <div className={styles.CopyRight}>
          © COPYRIGHT 2023 The Bouncy Castle King. ALL RIGHTS RESERVED.
        </div>
      </div>
    </div>
  );
}
