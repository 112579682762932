import React, { useEffect, useState } from "react";
import styles from "./page.module.css";

// Components
import dayjs from "dayjs";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ContactPage = () => {
  const [castles, setCastles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const [error, setError] = useState(false);
  const [noCastles, setNoCastles] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    eventDate: dayjs() || null,
    startTime: "09:00",
    endTime: "18:00",
    address: "",
    address2: "",
    city: "",
    province: "",
    postalCode: "",
    setupLocation: 0,
    setupSurface: 0,
    selectedCastles: ["K1"],
    message: "",
  });

  const setupLocation = [
    "Detached House",
    "Semi Detached House",
    "Town House",
    "Condo",
    "Apartment",
    "Public Park",
    "Venue",
    "Other",
  ];
  const setupSurface = ["Grass", "Pavement", "Other"];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (castleId) => {
    if (formData.selectedCastles.includes(castleId)) {
      setFormData((prev) => ({
        ...prev,
        selectedCastles: prev.selectedCastles.filter((id) => id !== castleId),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        selectedCastles: [...prev.selectedCastles, castleId],
      }));
    }
  };

  const convertTo12Hour = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const submit = async (event) => {
    event.preventDefault();

    if (formData.selectedCastles.length === 0) {
      setNoCastles(true);
      return;
    }

    setNoCastles(false);
    setLoading(true);

    const submitForm = {
      ...formData,
      startTime: convertTo12Hour(formData.startTime),
      endTime: convertTo12Hour(formData.endTime),
      setupLocation: setupLocation[formData.setupLocation],
      setupSurface: setupSurface[formData.setupSurface],
      eventDate: formData.eventDate?.format("dddd, MMMM D, YYYY"),
    };

    const response = await fetch("/api/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submitForm),
    });

    setLoading(false);

    if (response.ok) {
      setResponse(true);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const fetchCastles = async () => {
      const response = await fetch("/api/castle");
      const data = await response.json();

      data.forEach((castle) => {
        const img = new Image();
        img.src = castle.path;
      });

      setCastles(data);
    };

    const header = new Image();
    header.src = "/header-wide.webp";

    fetchCastles();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.container}>
        {/* Header */}
        <div className={styles.header}>
          <div className={styles.item}>
            <a href="/">
              <img src="/logo.png" alt="Bouncy Castle King Logo" />
            </a>
          </div>

          <a href="tel:416-525-6255" className={styles.contactInfo}>
            Call Now
            <br />
            416-525-6255
          </a>
        </div>
        {/* End of Header */}

        <div className={styles.page}>
          {!loading && !error && !response && (
            <>
              <h1 className={styles.title}>Book Your Bouncy Castle!</h1>
              <div className={styles.contactBody}>
                <h2 className={styles.subText}>
                  Call for more information or to book your Bouncy Castle now!{" "}
                  <br />
                  <a href="tel:416-525-6255" className={styles.subTextLink}>
                    416-525-6255
                  </a>
                </h2>
                <p className={styles.subTitle}>
                  Please note that bookings are not complete until we confirm
                  with you.
                </p>

                <form
                  style={{
                    width: "90%",
                  }}
                  onSubmit={submit}
                >
                  <div className={styles.subHeaderText}>
                    <span>Your Contact Information</span>
                  </div>
                  <div className={styles.formBody}>
                    <p className={styles.subTitle}>
                      Please let us know how we can get in touch to answer your
                      questions or book your bouncer!
                    </p>

                    <div className={styles.inputGroup}>
                      <TextField
                        label="First Name"
                        id="firstName"
                        name="firstName"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.firstName}
                        onChange={handleChange}
                      />

                      <TextField
                        label="Last Name"
                        id="lastName"
                        name="lastName"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>

                    <div className={styles.inputGroup}>
                      <TextField
                        label="Phone"
                        id="phone"
                        name="phone"
                        type="tel"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.phone}
                        onChange={handleChange}
                      />

                      <TextField
                        label="Email"
                        id="email"
                        name="email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className={styles.subHeaderText}>
                    <span>Your Event Details</span>
                  </div>
                  <div className={styles.formBody}>
                    <p className={styles.subTitle}>
                      Tell us about the event you&apos;re planning!
                    </p>

                    <div className={styles.inputGroup}>
                      <DatePicker
                        label="Event Date"
                        value={formData.eventDate}
                        onChange={(newValue) =>
                          setFormData((prev) => ({
                            ...prev,
                            eventDate: newValue,
                          }))
                        }
                        minDate={dayjs()}
                      />
                      <TextField
                        label="Start Time"
                        id="startTime"
                        name="startTime"
                        type="time"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.startTime}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <TextField
                        label="End Time"
                        id="endTime"
                        name="endTime"
                        type="time"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.endTime}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Address"
                        id="address"
                        name="address"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <TextField
                        label="Address Line 2"
                        id="address2"
                        name="address2"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.address2}
                        onChange={handleChange}
                      />
                      <TextField
                        label="City"
                        id="city"
                        name="city"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.city}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Province"
                        id="province"
                        name="province"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.province}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.inputGroup}>
                      <TextField
                        label="Postal Code"
                        id="postalCode"
                        name="postalCode"
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={formData.postalCode}
                        onChange={handleChange}
                      />
                      <InputLabel id="setupLocationLabel">
                        Setup Location
                      </InputLabel>
                      <Select
                        labelId="setupLocationLabel"
                        id="setupLocation"
                        label="Setup Location"
                        value={formData.setupLocation}
                        onChange={(value) => {
                          setFormData((prev) => ({
                            ...prev,
                            setupLocation: value.target.value,
                          }));
                        }}
                      >
                        <MenuItem value={0}>Detached House</MenuItem>
                        <MenuItem value={1}>Semi Detached House</MenuItem>
                        <MenuItem value={2}>Town House</MenuItem>
                        <MenuItem value={3}>Condo</MenuItem>
                        <MenuItem value={4}>Apartment</MenuItem>
                        <MenuItem value={5}>Public Park</MenuItem>
                        <MenuItem value={6}>Venue</MenuItem>
                        <MenuItem value={7}>Other</MenuItem>
                      </Select>
                    </div>
                    <div className={styles.inputGroup}>
                      <InputLabel
                        id="SetupSurfaceLabel"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        Setup Surface
                      </InputLabel>
                      <Select
                        labelId="SetupSurfaceLabel"
                        id="SetupSurface"
                        value={formData.setupSurface}
                        label="Setup Surface"
                        onChange={(value) => {
                          setFormData((prev) => ({
                            ...prev,
                            setupSurface: value.target.value,
                          }));
                        }}
                      >
                        <MenuItem value={0}>Grass</MenuItem>
                        <MenuItem value={1}>Pavement</MenuItem>
                        <MenuItem value={2}>Other</MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className={styles.subHeaderText}>
                    <span>Choose Your Bouncer</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                      marginTop: "20px",
                    }}
                  >
                    {castles.map((castle) => (
                      <div
                        key={castle.id}
                        className={styles.castle}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                          width: "230px",
                          height: "230px",
                          border: "1px solid black",
                          borderRadius: "10px",
                          margin: "10px",
                        }}
                        onClick={() => handleCheckboxChange(castle.castleId)}
                      >
                        <img
                          src={castle.path}
                          alt={castle.name}
                          style={{
                            width: "125px",
                            height: "125px",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "5px",
                          }}
                        >
                          <Checkbox
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <CheckCircleIcon color={"secondary"} />
                            }
                            name={castle.name}
                            checked={
                              formData.selectedCastles.includes(
                                castle.castleId
                              ) || false
                            }
                            style={{
                              margin: "0",
                            }}
                          />
                          <h3
                            style={{
                              color: "black",
                              margin: "0",
                            }}
                          >
                            {castle.castleId} {castle.name}
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginBottom: "10px" }}></div>
                  <div className={styles.subHeaderText}>
                    <span>More Questions?</span>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      label="Message"
                      id="message"
                      name="message"
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      margin="normal"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>

                  {noCastles && (
                    <h1
                      style={{
                        color: "red",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      *** Please select at least one bouncer ***
                    </h1>
                  )}

                  <button type="submit" className={styles.submit}>
                    <span
                      style={{
                        fontSize: "1.125rem",
                        lineHeight: "1.4em",
                        fontWeight: 500,
                      }}
                    >
                      Submit Your Request!
                    </span>
                  </button>
                </form>
              </div>
            </>
          )}

          {loading && (
            <div className={styles.loading}>
              <h1 className={styles.title}>Loading...</h1>
            </div>
          )}

          {error && (
            <div>
              <h1 className={styles.title}>
                An error has occurred. Please try again or call us!
              </h1>
            </div>
          )}

          {response && !error && (
            <div className={styles.response}>
              <h1 className={styles.title}>
                Thank you for your request! We will get back to you shortly.
              </h1>
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default ContactPage;
