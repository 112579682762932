import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
import Home from "./app/Home/Home";
import Contact from "./app/Contact/Contact";
import CastlePage from "./app/Bouncer/Bouncer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />

        {/* Bouncer Pages K */}
        <Route path="/bouncy-castles-K1" element={<CastlePage id={1} />} />
        <Route path="/bouncy-castles-K2" element={<CastlePage id={2} />} />
        <Route path="/bouncy-castles-K3" element={<CastlePage id={5} />} />
        <Route path="/bouncy-castles-K5" element={<CastlePage id={6} />} />
        <Route path="/bouncy-castles-K6" element={<CastlePage id={7} />} />
        {/* END OF Bouncer Pages K */}

        {/* Bouncer Pages M */}
        <Route path="/bouncy-castles-M1" element={<CastlePage id={8} />} />
        <Route path="/bouncy-castles-M2" element={<CastlePage id={9} />} />
        <Route path="/bouncy-castles-M4" element={<CastlePage id={10} />} />
        <Route path="/bouncy-castles-M5" element={<CastlePage id={11} />} />
        <Route path="/bouncy-castles-M6" element={<CastlePage id={12} />} />
        <Route path="/bouncy-castles-M7" element={<CastlePage id={13} />} />
        {/* END OF Bouncer Pages M */}

        {/* Bouncer Pages R */}
        <Route path="/bouncy-castles-R1" element={<CastlePage id={14} />} />
        <Route path="/bouncy-castles-R2" element={<CastlePage id={15} />} />
        <Route path="/bouncy-castles-R3" element={<CastlePage id={16} />} />
        <Route path="/bouncy-castles-R4" element={<CastlePage id={17} />} />
        {/* END OF Bouncer Pages R */}

        {/* Bouncer Pages G */}
        <Route path="/bouncy-castles-G1" element={<CastlePage id={18} />} />
        <Route path="/bouncy-castles-G2" element={<CastlePage id={19} />} />
        <Route path="/bouncy-castles-G3" element={<CastlePage id={20} />} />
        <Route path="/bouncy-castles-G4" element={<CastlePage id={21} />} />
        <Route path="/bouncy-castles-G5" element={<CastlePage id={22} />} />
        <Route path="/bouncy-castles-G6" element={<CastlePage id={23} />} />
        {/* END OF Bouncer Pages G */}

        {/* Bouncer Pages X */}
        <Route path="/bouncy-castles-X1" element={<CastlePage id={24} />} />
        <Route path="/bouncy-castles-X2" element={<CastlePage id={25} />} />
        <Route path="/bouncy-castles-X3" element={<CastlePage id={26} />} />
        <Route path="/bouncy-castles-X4" element={<CastlePage id={27} />} />
        <Route path="/bouncy-castles-X5" element={<CastlePage id={28} />} />
        {/* END OF Bouncer Pages X */}

        {/* 404 */}
        <Route path="*" element={<h1>404 - Page Not Found</h1>} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
