import React from "react";
import "./BouncerCard.style.css"; // Assuming you will add some CSS for styling

export const BouncerCard = ({
  image,
  title,
  size,
  price,
  setup,
  occupancy,
  maxWeight,
  maxAge,
  castleId,
}) => {
  return (
    <div
      className="bouncer-card"
      onClick={() => (window.location.href = `/bouncy-castles-${castleId}`)}
    >
      <img src={image} alt={title} className="bouncer-card-image" />
      <div className="bouncer-card-title">Bouncer Code: {castleId}</div>
      <div className="bouncer-card-body">
        <strong>Size: </strong>
        {size}
        <br />
        <strong>Setup: </strong>
        {setup}
        <br />
        <strong>Occupancy: </strong>
        up to {occupancy} kids
        <br />
        <strong>Maximum Weight Capacity: </strong>
        {maxWeight} Lbs
        <br />
        <strong>Recommended Age: </strong>
        {maxAge}
      </div>
      <div className="bouncer-card-price">
        <div>DETAILS</div>
        <div>${price} + HST</div>
      </div>
    </div>
  );
};
