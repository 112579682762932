import { useState } from "react";
import styles from "../../app/Home/home.module.css";
import castleStyle from "./castle.module.css";

const BouncerPage = ({ castle, pictures, mainPicture }) => {
  const [selectedImage, setSelectedImage] = useState("");

  const {
    name,
    description,
    tagLine,
    price,
    length,
    width,
    height,
    setup,
    occupancy,
    maxWeight,
    maxAge,
  } = castle;

  return (
    <div className={styles.container}>
      {/*Header*/}
      <div>
        <div className={styles.header}>
          <div className={styles.item}>
            <a href="/">
              <img src="/logo.png" alt="Bouncy Castle King Logo" />
            </a>
          </div>
          <a href="tel:416-525-6255" className={styles.contactInfo}>
            Call Now
            <br />
            416-525-6255
          </a>
        </div>
      </div>
      {/* Body */}
      <div className={castleStyle.castle}>
        <h1 className={castleStyle.title}>{name} Bouncy Castle</h1>
        <div className={castleStyle.body}>
          {/* Modal */}
          {selectedImage !== "" && (
            <div className={castleStyle.modal}>
              <span
                className={castleStyle.close}
                onClick={() => setSelectedImage("")}
              >
                x
              </span>
              <img
                alt={"Image of " + name}
                className={castleStyle.modalContent}
                src={selectedImage}
              ></img>
            </div>
          )}

          <div className={castleStyle.col}>
            <div className={castleStyle.gallery}>
              {/* Main Photo */}
              {mainPicture && (
                <img
                  src={mainPicture.path}
                  alt={"Image of " + name}
                  className={castleStyle.mainImg}
                ></img>
              )}

              {/* Gallery */}
              <div
                className={castleStyle.thumbnailGallery} /*Thumbnail images*/
              >
                {pictures.map((picture) =>
                  picture.path ? (
                    <div
                      key={picture.id}
                      className={castleStyle.thumbnailColumn}
                    >
                      <img
                        src={picture.path}
                        alt={"Image of " + name}
                        className={castleStyle.hoverShadow}
                        onClick={() => setSelectedImage(picture.path)}
                      />
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>

          {/* Info */}
          <div className={castleStyle.col}>
            <div className={castleStyle.info}>
              <div className={castleStyle.price}>Price: ${price} +HST</div>
              <div className={castleStyle.tagline}>{tagLine}</div>
              <div className={castleStyle.castleInfo}>
                <strong>Size: </strong>
                {length}’L x {width}’W x {height}’H
                <br />
                <strong>Setup: </strong>
                {setup}
                <br />
                <strong>Occupancy: </strong>
                {occupancy}
                <br />
                <strong>Maximum Weight Capacity: </strong>
                {maxWeight} Lbs
                <br />
                <strong>Recommended Age: </strong>
                {maxAge}
              </div>
              <div className={castleStyle.desc}>{description}</div>
              <div className={castleStyle.contact}>
                <p>
                  <strong>
                    <a href="tel:416-525-6255">Call (416) 525-6255</a> now to
                    book your bouncy castle!
                  </strong>
                </p>
                <p>
                  Price includes delivery, set up and take down, to Brampton,
                  Bolton, Caledon, Georgetown, Mississauga, Etobicoke, Oakville,
                  Vaughan, Maple, Woodbridge, Schomberg, North York.
                </p>
                <p>
                  Add $50 for delivery to Orangeville, Tottenham, Bradford,
                  Newmarket.
                </p>
                <p>
                  Add $75 Toronto east of Yonge to 404 Toronto south of
                  Lawrence, Aurora, Burlington, Alliston, Erin, Milton,
                  Stouffville. Call for Scarborough, Pickering, Hamilton,
                  Waterdown or Barrie.
                </p>
                <p>Call for your area if not listed, you might get lucky!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className={styles.Footer}>
        <div className={styles.BookNowContainer}>
          <a href="contact" className={styles.BookNow}>
            Book for Your Event Now!
          </a>
        </div>
        <div className={styles.CopyRight}>
          © COPYRIGHT 2023 The Bouncy Castle King. ALL RIGHTS RESERVED.
        </div>
      </div>
    </div>
  );
};

export default BouncerPage;
