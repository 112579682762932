import React, { useEffect, useState } from "react";
import { fetchCastle } from "../../util/api";
import BouncerPage from "../../components/BouncerPage/BouncerPage";

const CastlePage = ({ id }) => {
  const [castle, setCastle] = useState({
    id: 0,
    castleId: "",
    name: "",
    description: "",
    tagLine: "",
    price: 0,
    length: 0,
    width: 0,
    height: 0,
    setup: "",
    occupancy: 0,
    maxWeight: 0,
    maxAge: "",
  });
  const [pictures, setPictures] = useState([]);
  const [mainPicture, setMainPicture] = useState(null);

  useEffect(() => {
    const getCastle = async () => {
      try {
        const apiCastle = await fetchCastle(id);
        setCastle(apiCastle.castle);

        setMainPicture(() => {
          const main = apiCastle.pictures.find(
            (picture) => picture.type === "main"
          );
          return main ? main : { id: 0, castleId: "", path: "", type: "" };
        });

        setPictures(
          apiCastle.pictures.filter((picture) => picture.type !== "thumb")
        );

        apiCastle.pictures.forEach((picture) => {
          const img = new Image();
          img.src = picture.path;
        });
      } catch (error) {
        document.location.href = "/404";
      }
    };

    getCastle();
  }, [id]);

  return (
    <BouncerPage
      castle={castle}
      pictures={pictures}
      mainPicture={mainPicture}
    />
  );
};

export default CastlePage;
